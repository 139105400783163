import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/pages/Education.scss"

// import IconButton from "../assets/images/icons/button-arrow.svg"
import EbookImage from "../assets/images/education-ebook.svg"

import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"

export default function Education() {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "education-title" })} />
      <div className="education">
        <section className="education__hero">
          <div className="education__hero-content">
            <h1 className="education__hero-title">
              {intl.formatMessage({ id: "education-title" })}
            </h1>
            <div className="education__hero-flex">
              <div className="education__hero-texts">
                <p className="education__hero-text">
                  <strong>
                    {intl.formatMessage({ id: "education-hero-strong" })}
                  </strong>
                  {intl.formatMessage({ id: "education-hero" })}
                </p>
                <p className="education__hero-text education__hero-text--small">
                  {intl.formatMessage({ id: "education-hero-one" })}
                </p>
              </div>
              <div className="education__hero-image" />
              <p className="education__hero-text education__hero-text--small education__hero-text--mobile">
                {intl.formatMessage({ id: "education-hero-one" })}
              </p>
            </div>
          </div>
        </section>
        <section className="education__section-one">
          <p className="education__section-one-title">
            {intl.formatMessage({ id: "education-section-one-title" })}
          </p>
          <div className="education__section-one-content">
            <article className="education__section-one-item">
              <h3 className="education__section-one-item-title">
                {intl.formatMessage({ id: "education-section-one-item1" })}
              </h3>
              <p className="education__section-one-item-text">
                {intl.formatMessage({ id: "education-section-one-item1-text" })}
              </p>
            </article>
            <article className="education__section-one-item">
              <h3 className="education__section-one-item-title">
                {intl.formatMessage({ id: "education-section-one-item2" })}
              </h3>
              <p className="education__section-one-item-text">
                {intl.formatMessage({ id: "education-section-one-item2-text" })}
              </p>
            </article>
            <article className="education__section-one-item">
              <h3 className="education__section-one-item-title">
                {intl.formatMessage({ id: "education-section-one-item3" })}
              </h3>
              <p className="education__section-one-item-text">
                {intl.formatMessage({ id: "education-section-one-item3-text" })}
              </p>
            </article>
          </div>
          <h2 className="education__section-one-subtitle">
            {intl.formatMessage({ id: "education-section-one-title" })}
          </h2>
        </section>
        <section className="education__section">
          <div className="education__section-content">
            <article className="education__section-item education__section-item--1">
              <p className="education__section-item-text">
                {intl.formatMessage({ id: "education-section-two1" })}
              </p>
            </article>
            <article className="education__section-item education__section-item--2">
              <p className="education__section-item-text">
                {intl.formatMessage({ id: "education-section-two2" })}
              </p>
            </article>
            <article className="education__section-item education__section-item--3">
              <p className="education__section-item-text">
                {intl.formatMessage({ id: "education-section-two3" })}
              </p>
            </article>
          </div>
          {/* <button type="button" className="education__section-button">
            <p className="education__section-button-text">{intl.formatMessage({ id: "education-section-two-button" })}</p>
            <img src={IconButton} alt="" className="education__section-button-icon" />
          </button> */}
        </section>
        <section className="education__section">
          <div className="education__section-content">
            <article className="education__section-item education__section-item--4">
              <p className="education__section-item-text">
                {intl.formatMessage({ id: "education-section-three1" })}
              </p>
            </article>
            <article className="education__section-item education__section-item--5">
              <p className="education__section-item-text">
                {intl.formatMessage({ id: "education-section-three2" })}
              </p>
            </article>
            <article className="education__section-item education__section-item--6">
              <p className="education__section-item-text">
                {intl.formatMessage({ id: "education-section-three3" })}
              </p>
            </article>
          </div>
          {/* <button type="button" className="education__section-button">
            <p className="education__section-button-text">{intl.formatMessage({ id: "education-section-three-button" })}</p>
            <img src={IconButton} alt="" className="education__section-button-icon" />
          </button> */}
        </section>
        <section className="education__section">
          <div className="education__section-content">
            <article className="education__section-item">
              <img
                src={EbookImage}
                alt=""
                className="education__section-item-image"
              />
              <p className="education__section-item-text education__section-item-text--black">
                {intl.formatMessage({ id: "education-section-four1" })}
              </p>
            </article>
            <article className="education__section-item">
              <img
                src={EbookImage}
                alt=""
                className="education__section-item-image"
              />
              <p className="education__section-item-text education__section-item-text--black">
                {intl.formatMessage({ id: "education-section-four2" })}
              </p>
            </article>
            <article className="education__section-item">
              <img
                src={EbookImage}
                alt=""
                className="education__section-item-image"
              />
              <p className="education__section-item-text education__section-item-text--black">
                {intl.formatMessage({ id: "education-section-four3" })}
              </p>
            </article>
          </div>
          {/* <button type="button" className="education__section-button">
            <p className="education__section-button-text">{intl.formatMessage({ id: "education-section-four-button" })}</p>
            <img src={IconButton} alt="" className="education__section-button-icon" />
          </button> */}
        </section>
        <div className="education__text">
          <h2 className="education__text-title">
            {intl.formatMessage({ id: "education-text-one" })}
          </h2>
          <p className="education__text-description">
            {intl.formatMessage({ id: "education-text-two" })}
          </p>
        </div>
      </div>
    </Layout>
  )
}
